.header__search_form{
    margin-top: 88px;
    max-width: 608px;
    border-radius: 100px;
    margin-bottom: 80px;
   }
   
   .header__search_form_fieldset{
    position: relative;
    margin: 0;
    padding: 0;
    max-width: 608px;
    border: none;
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
   }
   
   .header__search_form_input{
    margin: 0;
    background: #FFFFFF;
    border: none;
    border-radius: 100px;
    width: 608px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding-top: 21px;
    padding-left: 24px;
    padding-bottom: 21px;
   }
   
   .searchForm__input-error::placeholder{
       margin: 0;
       padding: 0;
       font-family: "Inter", sans-serif;
       font-style: normal;
       font-weight: 400;
       font-size: 12px;
       line-height: 15px;
       color: #ff0000;
   }
   
   .header__search_form_button{
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 440px;
    bottom: 0;
    right: 0;
    margin: 0;
    border: none;
    border-radius: 100px;
    color: #FFFFFF;
    background: #2F71E5;
    width: 168px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    transition: 0.3s linear;
    cursor: pointer;
   }
   
   .header__search_form_button:hover{
       background: #347EFF;
   }
   
   .header__search_form_button:active{
       background: #2A65CC;
   }
   
   @media screen and (max-width: 900px){
       .header__search_form{
        max-width: 448px;
        margin-top: 48px;
        margin-bottom: 48px;
       }
   
       .header__search_form_input{
        width: 448px;
        padding-top: 17px;
        padding-left: 24px;
        padding-bottom: 17px;
       }
   
       .header__search_form_button{
          width: 160px;
          left: 292px; 
       }
   }
   
   @media screen and (max-width: 600px){
       .header__search_form{
        max-width: 288px;
        margin-top: 122px;
        margin-bottom: 32px;
       }
   
       .header__search_form_fieldset{
        max-width: 288px;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
       }
   
       .header__search_form_input{
        width: 288px;
        padding-left: 16px;
        padding-right: 16px;
       }
   
       .header__search_form_button{
        position: static;
        width: 288px;
        padding-top: 16px;
        padding-bottom: 16px;
       }
   
   }