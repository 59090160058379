.about {
  max-width: 1440px;
  margin: 0 auto;
  background: #ffffff;
  display: flex;
  justify-content: center;
  gap: 56px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.about__author_picture {
  max-width: 464px;
  width: 100%;
  height: 464px;
  border-radius: 50%;
}

.about__author_container {
  padding-top: 51px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.about__heading {
  margin: 0;
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1a1b22;
  max-width: 100%;
}

.about__paragraph_container {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}

.about__paragraph_text{
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
}

@media screen and (max-width: 900px) {
  .about {
    max-width: 768px;
    gap: 32px;
    padding: 40px;
  }

  .about__author_picture {
    max-width: 232px;
    height: 232px;
  }

  .about__paragragh_container {
    max-width: 424px;
    padding-top: 0;
    gap: 16px;
  }

  .about__heading {
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (max-width: 700px) {
  .about {
    flex-direction: column;
    align-items: center;
    max-width: 320px;
    padding-bottom: 128px;
    padding-top: 32px;
    gap: 24px;
  }

  .about__author_picture {
    max-width: 272px;
    height: 272px;
  }

  .about__paragraph_container {
    max-width: 288px;
    gap: 16px;
  }
}
