.footer {
  max-width: 1440px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}
.footer__copyright {
  padding-top: 32px;
  padding-bottom: 26px;
  padding-left: 104px;
  max-width: 433px;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b6bcbf;
}

.footer__links {
  list-style-type: none;
  padding-top: 30px;
  padding-bottom: 26px;
  padding-right: 106px;
  margin: 0;
  display: flex;
  gap: 40px;
}

.footer__links_featured {
  text-decoration: none;
  transition: 0.3s linear;
  display: flex;
  gap: 40px;
}
.footer__links_featured:hover {
  opacity: 0.8;
}
.footer__links_link {
  margin: 0;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
}

.footer__links_social {
  display: flex;
  gap: 26.89px;
  transition: 0.3s linear;
}

.footer__links_social:hover {
  opacity: 0.8;
}

.footer__link_social {
  margin: 0;
  width: 24px;
  height: 24px;
}

.github {
  background: url("../../images/github.svg") no-repeat center/cover;
}

.facebook {
  background: url("../../images/facebook-squar.svg") no-repeat center/cover;
}

@media screen and (max-width: 900px) {
  .footer {
    max-width: 768px;
  }
  .footer__copyright {
    padding-left: 40px;
    padding-top: 24px;
    padding-bottom: 18px;
  }

  .footer__links {
    padding-top: 21px;
    padding-bottom: 19px;
    padding-right: 21px;
  }

  .facebook {
    background: url("../../images/facebook-icon.svg") no-repeat center/cover;
  }
}

@media screen and (max-width: 700px) {
  .footer {
    max-width: 320px;
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0 auto;
  }

  .footer__copyright {
    padding-left: 16px;
    padding-top: 38px;
    padding-bottom: 22px;
    font-size: 16px;
    line-height: 22px;
  }

  .footer__links {
    padding-top: 20px;
    padding-left: 16px;
    justify-content: space-between;
  }

  .footer__links_featured {
    flex-direction: column;
  }

  .footer__links_social {
    gap: 25px;
  }

  .facebook {
    background: url("../../images/facebook-squar.svg") no-repeat center/cover;
  }
}
