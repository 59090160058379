.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  display: flex;
  visibility: hidden;
  opacity: 0;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 5;
  transition: 0.3s linear;
}

.popup__fadein {
  visibility: visible;
  opacity: 1;
}

.popup__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* width: 100%; */
  /* width: 600px; */
  /* max-height: 750px; */
}

.popup__close_button {
  background: url(../../images/Close_Icon.svg) no-repeat center/cover;
  /* display: flex; */
  /* justify-content: flex-end; */
  /* max-width: 100%; */
  /* align-self: flex-end; */
  color: inherit;
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  transition: opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  /* font: inherit; */

  /* 
  */
}

.popup__close_button:hover {
  opacity: 0.6;
  transform: rotate(45deg);
}

.popup__form_container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 430px;
  border-radius: 10px;
  background-color: white;
  max-height: 90vh;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.popup__header {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: #1a1b22;
  align-self: flex-start;
  padding-top: 34px;
  padding-left: 36px;
}

.popup__form {
  padding-bottom: 28px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border: none;
  width: 358px;
}

.popup__form_button_fieldset {
  border: none;
  margin: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-top: 30px;
  max-width: 358px;
}

.popup__form_button {
  background: #2f71e5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  border: none;
  padding-top: 20px;
  padding-left: 115px;
  padding-bottom: 20px;
  padding-right: 115px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 360px;
  transition: opacity 0.3s ease-in-out;
}

.popup__form_button:hover {
  background: #347eff;
}

.popup__form_button:active {
  background: #2a65cc;
}

.popup__form_button_disabled {
  background: #e6e8eb;
  color: #b6bcbf;
  box-sizing: border-box;
  cursor: default;
}

.popup__form_button_disabled:hover {
  background: #e6e8eb;
}

.popup__link {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding-bottom: 28px;
}

.popup__link_span {
  margin: 0;
  text-decoration: none;
  color: #2f71e5;
  cursor: pointer;
}

.popup__form_submitError {
  margin: 0 auto;
  padding-top: 17px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ff0000;
}

@media screen and (max-width: 440px) {
  .popup__container {
    width: 100%;
    /* width: 100vw; */
    padding-top: 56px;
  }

  .popup__close_button {
    margin-bottom: 13.5px;
    width: 24px;
    height: 24px;
    margin-top: 16px;
    margin-right: 16px;
  }

  .popup__form_container {
    width: 100%;
  }

  .popup__form {
    padding-top: 0px;
    padding-bottom: 16px;
    max-width: 288px;
    width: 100%;
  }

  .popup__header {
    font-size: 24px;
    line-height: 28px;
    padding-top: 16px;
    padding-left: 17px;
    padding-bottom: 18px;
  }

  .popup__form_submitError {
    padding-top: 5px;
  }

  .popup__form_button_fieldset {
    padding-top: 46px;
    max-width: 100%;
  }

  .popup__form_button {
    font-size: 18px;
    line-height: 24px;
    width: 288px;
    padding-top: 20px;
    padding-left: 79px;
    padding-bottom: 20px;
    padding-right: 79px;
  }

  .popup__link {
    padding-bottom: 100%;
  }
}
