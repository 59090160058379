.popup-confirm__body {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
  border-radius: 16px;
  min-width: 430px;
  min-height: 182px;
}

.popup__close {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -35px;
  right: -35px;
  padding: 0;
  transition: 0.6s ease;
  background-image: url(../../images/Close_Icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.popup__close:hover {
  opacity: 0.6;
}

.popup_open {
  opacity: 1;
  visibility: visible;
  transition: opacity 900ms 100ms;
  pointer-events: all;
}

.popup-confirm__link {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #2f71e5;
  margin: 0 166px 51px 36px;
  text-decoration: none;
  cursor: pointer;
}

.popup-confirm__link:hover {
  opacity: 0.6;
}

.popup-confirm__title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  max-width: 315px;
  margin: 43px 79px 14px 36px;
}

@media screen and (max-width: 767px) {
  .popup__body {
    max-width: 320px;
    max-height: 560px;
  }

  .popup__close {
    width: 24px;
    height: 24px;
    top: -27px;
    right: 9px;
  }

  .popup-confirm__body {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
    border-radius: 16px;
    min-width: 288px;
    min-height: 160px;
  }

  .popup-confirm__title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    max-width: 288px;
    margin: 30px 16px 14px;
  }

  .popup-confirm__link {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #2f71e5;
    margin: 0 16px 36px;
  }
}
