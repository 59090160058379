.preloader {
  margin: 0;
  background: #f5f6f7;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1440px;
  height: 282px;
}

.circle-preloader {
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid #444;
  border-bottom-color: #888;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  animation: spin 0.75s infinite linear;
}

.pharagraph-preloader {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #b6bcbf;
  padding: 178px 535px 80px;
  margin: 0;
  white-space: nowrap;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .preloader {
    margin: 0;
    background: #f5f6f7;
    position: relative;
    overflow: hidden;
    height: 282px;
  }
  .pharagraph-preloader {
    padding: 178px 200px 80px;
    margin: 0;
    white-space: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .preloader {
    margin: 0;
    background: #f5f6f7;
    position: relative;
    overflow: hidden;
    min-width: 320px;
    height: 150px;
  }

  .pharagraph-preloader {
    padding: 110px 50px 50px;
    margin: 0;
    white-space: nowrap;
  }
}
