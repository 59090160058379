.header {
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
  background: url("../../images/phone-backgroung.png") no-repeat center/cover;
}
.header__color_white {
  background: #ffffff;
}
.header__content_title {
  font-family: "Roboto Slab", sans-serif;
  font-size: 60px;
  line-height: 64px;
  padding-left: 0;
  padding-top: 80px;
  text-align: left;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  max-width: 608px;
}

.header__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header__content_subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding-top: 32px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  max-width: 608px;
}

@media screen and (max-width: 900px) {
  .header {
    max-width: 900px;
  }

  .header__top-container {
    padding-top: 14px;
  }

  .header__logo {
    padding-top: 8.73px;
    padding-left: 40px;
  }

  .header__nav-container {
    max-width: 600px;
    width: 100%;
    gap: 16px;
    padding-right: 40px;
  }

  .header__signin-button {
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-bottom: 12px;
    font-size: 16px;
  }

  .header__button-profile_white {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 13px;
  }

  .header__content-container {
    align-items: flex-start;
    padding-left: 163px;
    max-width: 900px;
  }

  .header__title {
    font-size: 36px;
    line-height: 40px;
    padding-top: 38px;
    max-width: 358px;
  }

  .header__subtitle {
    font-size: 18px;
    line-height: 24px;
    max-width: 452px;
    padding-top: 16px;
  }
}

@media screen and (max-width: 700px) {
  .header {
    max-width: 100vw;
  }

  .header__logo {
    max-width: 117px;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .header__nav-container {
    display: none;
  }

  .header__signin-button {
    display: none;
  }

  .header__button-profile_white {
    display: none;
  }

  .header__nav-button {
    display: flex;
  }

  .header__content-container {
    padding: 0;
    align-items: center;
  }

  .header__content_title {
    max-width: 452px;
    padding-top: 30px;
    font-size: 36px;
    line-height: 40px;
  }

  .header__content_subtitle {
    max-width: 452px;
  }
}

@media screen and (max-width: 500px) {
  
  .header__content_title {
    max-width: 288px;
  
  }

  .header__content_subtitle {
    max-width: 288px;
  }
}