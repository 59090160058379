.main {
  max-width: 1440px;
  margin: 0 auto;
  padding-top: 0;
}

.main__container {
  background: #f5f6f7;
  margin: 0 auto;
}

.main__header {
  margin: 0;
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  padding-left: 80px;
  padding-top: 104px;
}

.main__news_container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  max-width: 1248px;
}

.main__button {
  margin: 3% 0%;
  visibility: visible;
  opacity: 1;
  width: 288px;
  background: #ffffff;
  border-radius: 80px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1a1b22;
  border: none;
  padding: 20px 98px;
  /* padding-top: 20px; */
  /* padding-left: 99px; */
  /* padding-bottom: 20px; */
  /* padding-right: 98px; */
  cursor: pointer;
  transition: 0.3s linear;
}

.main__button:hover {
  background: #e8e8e8;
}

@media screen and (max-width: 900px) {
  .main {
    max-width: 100vw;
  }

  .main__header {
    padding-top: 40px;
    padding-left: 40px;
    font-size: 30px;
    line-height: 34px;
  }

  .main__news_container {
    max-width: 696px;
  }

  .main__button {
    width: 240px;
    padding: 16px 74px;
    /* padding-top: 16px;
    padding-left: 75px;
    padding-bottom: 16px;
    padding-right: 74px; */
  }
}

@media screen and (max-width: 700px) {
  .main__header {
    padding-top: 32px;
    padding-left: 16px;
    margin: 0 auto;
  }

  .main__news_container {
    /* max-width: 288px; */
    padding-bottom: 24px;
  }

  .main__button {
    width: 288px;
    padding: 16px 98px;
    /* padding-top: 16px; */
    /* padding-left: 99px; */
    /* padding-bottom: 16px; */
    /* padding-right: 98px; */
  }
}
