body {
  margin: 0;
  padding: 0;
}

@import url("./vendor/normalize.css");
@import url("./fonts/fonts.css");
@import url("./components/Header/Header.css");
@import url("./components/NavBar/NavBar.css");
@import url("./components/App/App.css");
@import url("./components/NewsCard/NewsCard.css");
@import url("./components/NewsCardList/NewsCardList.css");
@import url("./components/SavedNewsHeader/SavedNewHeader.css");
@import url("./components/PopupWithForm/PopupWithForm.css");
@import url("./components/SignInPopup/SignInPopup.css");
@import url("./components/SignUpPopup/SignUpPopup.css");
@import url("./components/About/About.css");
@import url("./components/Footer/Footer.css");
@import url("./components/Main/Main.css");
@import url("./components/SearchForm/SearchForm.css");
@import url("./components//SavedNews/SavedNews.css");
@import url("./components/PopupConfirm/PopupConfirm.css");
@import url("./components/Preloader/Preloader.css");
@import url("./components/NothingFound/NothingFound.css");
