.card-list {
  max-width: 1248px;
  width: 100%;
  /* padding: 64px 15px; */
  margin: 0 auto;
}

.card-list__backgroundcolor_grey {
  background-color: #f5f6f7;
  max-width: 1440px;
  margin: 0% auto;
  padding: 64px 0;
}

.card-list__list {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 16px;
  margin: 0 auto;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.card-list__list_savedarticles {
  width: 90%;
}

@media screen and (max-width: 1280px) {
  .card-list {
    /* max-width: 300px; */
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .card-list__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    padding-bottom: 0px;
    margin:  auto;
  }

  /* .card-list__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding-bottom: 0px;
  } */
}

@media screen and (max-width: 1024px) {
  .card-list {
    /* max-width: 696px; */
    /* padding-top: 32px; */
    /* padding-bottom: 32px; */
  }

  .card-list__list {
    /* grid-template-columns: repeat(2, 1fr); */
    gap: 8px;
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 810px) {
  .card-list {
    max-width: 700px;
    /* margin: 0% auto; */

    padding-top: 58px;
    padding-bottom: 21px;
  }

  .card-list__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

@media screen and (max-width: 700px) {
  .card-list {
    max-width: 450px;
    /* margin: 0% auto; */

    padding-top: 58px;
    padding-bottom: 21px;
  }

  .card-list__list {
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}




