.element {
  /*width: 400px;*/
  height: 576px;
  background: #ffffff;
  border: 0px solid #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin: 0 auto;
}
.element__image {
  background: url("../../images/lake-photo.png") no-repeat center;
  width: 400px;
  width: 100%;
  height: 272px;
  background-size: cover;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.element__flag {
  background: url("../../images/bookmark-noraml.svg") no-repeat center;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 24px;
  right: 24px;
  border-radius: 8px;
  background-color: #ffffff;
}

.element__tip {
  display: none;
  font-family: "Roboto", sans-serif;
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  text-align: center;
  background: #ffffff;
  box-shadow: 0px 10px 22px rgba(59, 74, 116, 0.1);
  border-radius: 10px;
  top: 24px;
  right: 69px;
  padding: 13px 20px;
}
.element__flag:hover + .element__tip {
  display: block;
}
.element__textcontainer {
  padding: 20px 24px 24px;
}
.element__trash {
  background: url("../../images/trash-normal.svg") no-repeat center;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 24px;
  right: 24px;
  border-radius: 8px;
  background-color: #ffffff;
}

.element__note {
  position: absolute;
  top: 24px;
  left: 24px;
  padding: 8px 22px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  border-radius: 10px;
  color: #000000;
  background-color: #ffffff;
}

.element__trash:hover + .element__tip {
  display: block;
}

.element__date {
  color: #b6bcbf;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.element__title {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;

  color: #1a1b22;
  padding: 12px 0 16px;
  margin: 0;
}

.element__text {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 40px;
  color: #1a1b22;
  margin: 0;
}

.element__source {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #b6bcbf;
  margin: 0;
  text-transform: uppercase;
}

.element__source a {
  text-decoration: none;
  color: #b6bcbf;
}

@media screen and (max-width: 960px) {
  .element {
    margin: 0 auto;
  }

  .element__textcontainer {
    padding: 16px;
  }
  .element__text {
    padding-bottom: 12px;
  }
}
@media screen and (max-width: 760px) {
  .element {
    /* width: 500px; */
    margin: 0 auto;
  }
}
@media screen and (max-width: 550px) {
  .element {
    width: 350px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 365px) {
  .element {
    width: 300px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 320px) {
  .element {
    width: 288px;
    margin: 0 auto;
  }
}
