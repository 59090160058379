.page {
    position:relative;
    max-width: 100vw;
    min-height: 100vh;
    margin: auto;
    font-family: "Inter", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    color: #000000;
    background: #ffffff 100vw 100vh;
    -webkit-background-size: 100vw;
    -moz-background-size: 100vw;
    -o-background-size: 100vw;
    background-size: 100vw;
  }